import Joi from "joi";
import { DateTime } from "luxon";
import TimeUtils, { DATE_FORMAT } from "@/store/modules/helpers/TimeUtils";

/*****************************
 * Joi Schema Helper Functions
 *****************************/

/**
 * This function takes in a list of valid entries and a default value, and will return
 * a Joi schema that will validate a property against the valid entries. If the property
 * isn't set or is not a valid entry, it will set the default value instead of throwing an error.
 *
 * @param validEntries
 * @param defaultValue
 * @returns {Joi.StringSchema<string>}
 */
const getValidStringOrDefault = (validEntries, defaultValue = null) => {
  return Joi.string()
    .empty(Joi.not(...validEntries))
    .default(defaultValue);
};

/**
 * This function takes in a schema for an object, and returns a Joi schema that will validate a property against
 * the schema provided. If the property is null or undefined, it will return the default schema.
 *
 * @param schema
 * @param options
 * @returns {Joi.ObjectSchema<any>}
 */
const getValidObjectOrDefault = (schema, options = { allowUnknown: true, stripUnknown: false }) => {
  // get the default schema in the scenario where the property is null or undefined.
  const defaultValue = Joi.object()
    .keys(schema)
    .validate({}).value;

  return Joi.alternatives()
    .try(
      Joi.object().keys(schema).required(), // tries the schema validation first
      Joi.any().empty(Joi.any()), // if first validation fails, the second will always set it to empty which will set the default value
    )
    .default(defaultValue)
    .options(options);
};

/**
 * This function takes a default value as a parameter. It returns a Joi schema that will validate the value is
 * a valid UUID. If not, it will return the default value.
 *
 * A custom validation function was needed here to inverse the UUID validation in the empty() function.
 *
 * @param defaultValue
 * @returns {Joi.StringSchema<string>}
 */
const getValidUUIDOrDefault = (defaultValue = null) => {
  return Joi.alternatives()
    .try(
      Joi.string().uuid().allow(null), // tries to validate the uuid
      Joi.any().empty(Joi.any()), // if not valid uuid, this will set to empty which will set the default value
    )
    .default(defaultValue);
};

const yearMonthDayDateValidationFunction = (value) => {
  const date = DateTime.fromFormat(value, TimeUtils.DATE_FORMAT);
  if (date.isValid) {
    return value;
  } else {
    throw new Error("Invalid date");
  }
};

const getValidDateOrDefault = (defaultValue) => {
  return Joi.alternatives()
    .try(
      Joi.string().custom(yearMonthDayDateValidationFunction, "date validation"),
      Joi.any().empty(Joi.any()),
    )
    .default(defaultValue);
};
/**
 * This function returns a Joi validation schema that will always set the property to null.
 *
 * @returns {Joi.StringSchema<string>}
 */
const stringMustBeNull = () => {
  return Joi.any()
    .empty(Joi.any())
    .default(null);
};

const today = (minusDays = 0) => {
  return DateTime.now()
    .minus({ days: minusDays })
    .toFormat(DATE_FORMAT);
}

/*****************************
 * Default Joi Schemas
 *
 * Functions that return a Joi schema to be used for validation. These schemas will validate and return a default
 * json if the input is null/undefined/empty.
 *****************************/

const supportedTimeframes = [
  "lastday",
  "week",
  "lastweek",
  "last7days",
  "last30days",
  "last90days",
  "month",
  "lastmonth",
  "current_custom_period",
  "last_custom_period",
  "time_range",
];

const getDefaultValidPreferencesValidation = () => {
  const schema = {
    language: getValidStringOrDefault(["en", "fr", "es"], "en"),
    theme: getValidStringOrDefault(["light", "dark"], "dark"),
    tilelytics: getValidObjectOrDefault(
      {
        selected_time_option: getValidStringOrDefault(supportedTimeframes, "last7days"),
        selected_start_date: Joi.when("selected_time_option", {
          is: "time_range",
          then: getValidDateOrDefault(today()),
          otherwise: stringMustBeNull(),
        }),
        selected_end_date: Joi.when("selected_time_option", {
          is: "time_range",
          then: getValidDateOrDefault(today(30)), // 30 days in the past since the default timeframe is `last30Days`
          otherwise: stringMustBeNull(),
        }),
        selected_tilelytics_filter_id: getValidUUIDOrDefault(),
      },
      { allowUnknown: true, stripUnknown: true },
    ),
  };

  return getValidObjectOrDefault(schema);
};

/**
 * This functions takes the preferences as the parameter from the backend API and makes sure they are valid. If any
 * properties are invalid, null or undefined, instead of throwing an error a default value will be set. This way
 * a user will always have valid preferences.
 *
 * For Tilelytics, the properties used in Tileplus are ignored in the schema. This means they will be saved but not validated.
 * Only preferences used in Tilelytics should be validated here.
 *
 * @param preferences
 * @returns {*}
 */
export function validateAndTransformUserPreferences(preferences) {
  const preferencesSchema = getDefaultValidPreferencesValidation();
  const results = preferencesSchema.validate(preferences ?? {});
  return results.value;
}
