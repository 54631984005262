import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    meta: {
      requiresAuth: false,
    },
    redirect: `/home`,
  },
  {
    path: "/login-callback",
    name: "login-callback",
    component: () => import("../views/LoginCallbackView"),
  },
  {
    path: "/unauthorized",
    name: "UserNotAuthorized",
    meta: {
      layout: "simple-layout",
      layoutProps: { isLayoutCentered: true },
      requiresAuth: false,
    },
    component: () => import("../views/UserNotAuthorizedView"),
  },
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/home",
        name: "Home",
        meta: {
          layout: "simple-layout",
          layoutProps: { isLayoutCentered: true },
        },
        component: () => import("../views/HomeView"),
      },
      {
        path: "company-overview",
        name: "CompanyOverview",
        meta: {
          layout: "default-layout",
          layoutProps: { isMainHeightMaximized: true },
          requiresAuth: true,
        },
        component: () => import("../views/CompanyOverviewView"),
      },
      {
        path: "/:factoryId/dashboard",
        name: "Dashboard",
        meta: {
          layout: "default-layout",
          layoutProps: { isMainHeightMaximized: true },
          requiresAuth: true,
        },
        component: () => import("../views/DashboardView"),
      },
      {
        path: "performance-manager/shift-changes",
        name: "ShiftChanges",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/ShiftChangesView"),
      },
      {
        path: "performance-manager/line-starts",
        name: "LineStarts",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/LineStartsView"),
      },
      {
        path: "performance-manager/planned-downtimes-with-target",
        name: "PlannedDowntimesWithTarget",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/PlannedDowntimesWithTargetView"),
      },
      {
        path: "/:factoryId/availability-downtime",
        name: "AvailabilityAndDowntime",
        meta: {
          layout: "default-layout",
          layoutProps: { isMainHeightMaximized: true },
          requiresAuth: true,
        },
        component: () => import("../views/AvailabilityAndDowntimeView"),
      },
      {
        path: "/:factoryId/production-data-export",
        name: "ProductionDataExport",
        meta: {
          layout: "default-layout",
          layoutProps: { isMainHeightMaximized: true },
          requiresAuth: true,
        },
        component: () => import("../views/ProductionDataExportView"),
      },
      {
        path: "/:factoryId/my-reports",
        name: "MyReports",
        meta: { layout: "default-layout", layoutProps: { isMainHeightMaximized: true }, requiresAuth: true },
        component: () => import("../views/MyReportsView"),
      },
      {
        path: "/:factoryId/my-reports/creation",
        name: "ReportCreation",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/MyReportView"),
      },
      {
        path: "/:factoryId/my-reports/:id",
        name: "ReportEdition",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/MyReportView"),
      },
      {
        path: "factories",
        name: "Factories",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/FactoriesView"),
      },
      {
        path: "factory/:id",
        name: "FactoryWizard",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/FactoryView"),
      },
      {
        path: "customPeriods",
        name: "CustomPeriods",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/CustomPeriodsView"),
      },
      {
        path: "customPeriods/creation",
        name: "CustomPeriodCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/CustomPeriodView"),
      },
      {
        path: "customPeriods/:id",
        name: "CustomPeriodEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/CustomPeriodView"),
      },
      {
        path: "terms-of-service",
        name: "TermsOfService",
        meta: { requiresAuth: true },
        component: () => import("../views/TermsOfServiceView"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.addRoute({
  path: "/*",
  name: "PageNotFound",
  meta: {
    layout: "simple-layout",
    layoutProps: { isLayoutCentered: true },
  },
  component: () => import("../views/PageNotFoundView.vue"),
});
