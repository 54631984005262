import FactoryService from "../../components/factory/FactoryService";
import NodeCache from "node-cache";
import CustomPeriodService from "../../components/admin/customperiod/CustomPeriodService";
import { DateTime, Interval } from "luxon";

export default {
  namespaced: true,

  state: {
    activeFactoryLoaded: false,
    activeFactory: null,
    activeFactoryProductionUnits: [],
    activeFactoryProductionUnitNames: [],
    activeFactoryWorkShiftsByProductionUnitId: new Map(),
    activeFactoryWorkShiftIdNameMappings: [],
    activeFactoryWorkShiftNames: [],
    activeFactorySKUs: [],

    factoryCache: new NodeCache({ stdTTL: 3600, checkperiod: 120, deleteOnExpire: true }),

    lastFactoryQueryDateTime: null,

    customPeriods: [],

    timeRange: {
      value: null,
      start_date: null,
      end_date: null,
    },

    asideFilterDrawerVisibility: false,
    isContinuousGraphLines: true,
  },

  getters: {
    isActiveFactoryLoaded(state) {
      return state.activeFactoryLoaded;
    },
    activeFactory(state) {
      return state.activeFactory;
    },
    activeFactoryCurrency(state) {
      return state.activeFactory ? state.activeFactory.currency : "";
    },
    activeFactoryWeekFirstDay(state) {
      return state.activeFactory && state.activeFactory.weekFirstDay ? state.activeFactory.weekFirstDay : 1;
    },
    activeFactoryProductionUnits(state) {
      return state.activeFactoryProductionUnits;
    },
    activeFactoryProductionUnitNames(state) {
      return state.activeFactoryProductionUnitNames;
    },
    activeFactoryWorkShiftNames(state) {
      return state.activeFactoryWorkShiftNames;
    },
    activeFactoryWorkShiftIdNameMappings(state) {
      return state.activeFactoryWorkShiftIdNameMappings;
    },
    activeFactorySKUs(state) {
      return state.activeFactorySKUs;
    },
    timeRange(state) {
      return state.timeRange;
    },
    customPeriods(state) {
      return state.customPeriods;
    },
    currentCustomPeriod(state) {
      if (state.activeFactoryLoaded && state.customPeriods.length > 0) {
        let nowInFactoryTz = DateTime.now().setZone(state.activeFactory.timezone, true);
        const currentPeriod = state.customPeriods.find((period) => {
          return period.start <= nowInFactoryTz && period.end > nowInFactoryTz;
        });
        return currentPeriod ? currentPeriod : null;
      }
      return null;
    },
    lastCustomPeriod(state) {
      if (state.activeFactoryLoaded && state.customPeriods.length > 0) {
        let nowInFactoryTz = DateTime.now().setZone(state.activeFactory.timezone, true);

        let periodsBeforeNow = state.customPeriods.filter((period) => period.end < nowInFactoryTz);
        periodsBeforeNow.sort((p1, p2) => (p1.start <= p2.start ? -1 : p1.start > p2.start ? 1 : 0));

        if (periodsBeforeNow.length > 0) {
          return periodsBeforeNow[periodsBeforeNow.length - 1];
        } else {
          return null;
        }
      }
      return null;
    },
    asideFilterDrawerVisibility(state) {
      return state.asideFilterDrawerVisibility;
    },
    isContinuousGraphLines(state) {
      return state.isContinuousGraphLines;
    },
  },
  actions: {
    setActiveFactory({ state, commit }, activeFactory) {
      // Check if the factory is in the cache
      commit("setActiveFactoryLoaded", false);
      const cachedFactory = state.factoryCache.get(activeFactory.id);
      if (cachedFactory) {
        commit("setActiveFactory", cachedFactory.factorySettings);
        commit("setActiveFactoryProductionUnits", cachedFactory.productionUnits);
        commit("updateActiveFactoryWorkShiftsByProductionUnitId", cachedFactory.workShiftsByProductionUnitId);
        commit("updateWorkShiftIdNameMappings", cachedFactory.workShiftIdNameMappings);
        commit("updateActiveFactorySKUs", cachedFactory.skus);
        commit("setActiveFactoryLoaded", true);
      } else {
        commit("setActiveFactory", activeFactory);
        commit("setActiveFactoryProductionUnits", activeFactory.productionUnits);
        commit("setActiveFactoryLoaded", true);

        FactoryService.getFactoryWorkShifts(activeFactory.id)
          .then((factoryWorkShiftsResponse) => {
            let workShiftIdNameMappings = [];
            let workShiftsByProductionUnitId = new Map();
            factoryWorkShiftsResponse.data.forEach((puWorkShifts) => {
              workShiftsByProductionUnitId.set(puWorkShifts.production_unit_id, puWorkShifts.work_shift);
              puWorkShifts.work_shift.forEach((workShiftIdName) => {
                workShiftIdNameMappings.push(workShiftIdName);
              });
            });
            commit("updateActiveFactoryWorkShiftsByProductionUnitId", workShiftsByProductionUnitId);
            commit("updateWorkShiftIdNameMappings", [...new Set(workShiftIdNameMappings)]);

            FactoryService.getFactoryProducts(activeFactory.id)
              .then((factoryProductsResponse) => {
                const skus = factoryProductsResponse.data.map((product) => product.sku);
                commit("updateActiveFactorySKUs", skus);

                commit("setActiveFactoryLoaded", true);
                // Cache the factory
                const cachedFactory = {
                  factorySettings: activeFactory,
                  productionUnits: state.activeFactoryProductionUnits,
                  workShiftsByProductionUnitId: state.activeFactoryWorkShiftsByProductionUnitId,
                  workShiftIdNameMappings: state.activeFactoryWorkShiftIdNameMappings,
                  skus: state.activeFactorySKUs,
                };
                commit("cacheFactory", { factoryId: activeFactory.id, cachedFactory: cachedFactory });
              })
              .catch((error) => {
                commit("setActiveFactoryLoaded", true);
                throw error;
              });
          })
          .catch((error) => {
            commit("setActiveFactoryLoaded", true);
            throw error;
          });
      }
    },
    setTimeRange({ commit, dispatch }, newTimeRange) {
      commit("updateTimeRange", newTimeRange);
      dispatch("user/updateTimeRangeOption", newTimeRange, { root: true });
    },
    fetchCustomPeriods({ commit }) {
      CustomPeriodService.getCustomPeriods()
        .then((httpResponse) => {
          const customPeriods = httpResponse.data.map((p) => {
            const start = DateTime.fromFormat(p.start_date, "yyyy-LL-dd");
            const end = DateTime.fromFormat(p.end_date, "yyyy-LL-dd");
            const diff = Interval.fromDateTimes(start, end);
            const dayCount = diff.length("days");
            return {
              id: p.id,
              name: p.name,
              start_date: p.start_date,
              end_date: p.end_date,
              day_count: dayCount,
              start: start,
              end: end,
            };
          });
          customPeriods.sort((p1, p2) => (p1.start <= p2.start ? 1 : p1.start > p2.start ? -1 : 0));
          commit("setCustomPeriods", customPeriods);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    updateAsideFilterDrawerVisibility({ commit }, value) {
      commit("updateAsideFilterDrawerVisibility", value);
    },
    updateShowContinuousGraphLines({ commit }, value) {
      commit("setShowContinuousGraphLines", value);
    },
  },
  mutations: {
    setActiveFactoryLoaded(state, booleanValue) {
      state.activeFactoryLoaded = booleanValue;
    },
    setActiveFactory(state, data) {
      state.activeFactory = data;
    },
    setActiveFactoryProductionUnits(state, list) {
      state.activeFactoryProductionUnits = list;
      state.activeFactoryProductionUnitNames = list.map((pu) => pu.name);
    },
    updateActiveFactoryWorkShiftsByProductionUnitId(state, wsByPu) {
      state.activeFactoryWorkShiftsByProductionUnitId = wsByPu;
    },
    updateWorkShiftIdNameMappings(state, wsIdNames) {
      state.activeFactoryWorkShiftIdNameMappings = wsIdNames;
      let factoryWsNames = wsIdNames.map((idName) => idName.name);
      state.activeFactoryWorkShiftNames = [...new Set(factoryWsNames.sort())];
    },
    updateActiveFactorySKUs(state, skus) {
      state.activeFactorySKUs = skus.sort();
    },
    cacheFactory(state, { factoryId, cachedFactory }) {
      state.factoryCache.set(factoryId, cachedFactory);
    },
    updateTimeRange(state, newTimeRange) {
      state.timeRange = newTimeRange;
    },
    setCustomPeriods(state, customPeriods) {
      state.customPeriods = customPeriods;
    },
    updateAsideFilterDrawerVisibility(state, value) {
      state.asideFilterDrawerVisibility = value;
    },
    setShowContinuousGraphLines(state, value) {
      state.isContinuousGraphLines = value;
    },
  },
};
