import http from "../../BaseConfigurationApiHttp";
import { DIRECTOR_ROLE, PRESENTER_ROLE, SUPERVISOR_ROLE } from "../../store/modules/user";
import i18n from "../../i18n";
import { validateAndTransformUserPreferences } from "@/components/user/UserPreferencesService";

class UserService {
  async getUserPreferences() {
    const response = await http.get(`/user/preferences`);
    response.data = validateAndTransformUserPreferences(response.data);
    return response;
  }
  updatePreferences(newPreferences) {
    return http.put(`/user/preferences`, newPreferences);
  }
  getDisplayRoleNameForValue(roleValue) {
    let availableRole = this.getAvailableRoles().find((r) => r.value === roleValue);
    return availableRole.text;
  }
  getAvailableRoles() {
    return [
      { value: PRESENTER_ROLE, text: i18n.t("user.roles.presentation") },
      { value: SUPERVISOR_ROLE, text: i18n.t("user.roles.operation") },
      { value: DIRECTOR_ROLE, text: i18n.t("user.roles.management") },
    ];
  }

  getProductionUnitWorkShifts() {
    return http.get(`user/work-shifts`);
  }
}

export default new UserService();
